.why {
  &__list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    padding: 0 10px;
    margin: 0 auto;
    @include sm {
      max-width: 510px;
    }
    @include md {
      max-width: 690px;
    }
    @include lg {
      max-width: 930px;
    }
    @include xl {
      max-width: 1110px;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
    @include sm {
      width: calc(100% / 12 * 12);
    }
    @include md {
      width: calc(100% / 12 * 6 - 15px);
    }
    @include lg {
      width: calc(100% / 12 * 4 - 20px);
    }

    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      background: $color-white;
      border-radius: 50%;
      transition: 0.5s;
      color: $color-icons;
      font-size: 35px;
      overflow: hidden;
      box-shadow: 0 0 25px $box-shadow-black;
      &:hover {
        box-shadow: 0 0 25px $box-shadow-green;
      }
    }
    &-title {
      font-weight: 600;
      font-size: 18px;
      position: relative;
      text-align: center;
      padding: 0;
      margin: 0;
      min-height: 42px;
      &::after {
        content: "";
        position: absolute;
        display: block;
        width: 50px;
        height: 2px;
        background: $color-primary;
        top: 50px;
        left: calc(50% - 25px);
      }
    }
    &-subtitle {
      line-height: 24px;
      font-size: 14px;
      text-align: center;
      padding: 0;
      margin: 0;
    }
  }
}
