.logo {
  margin: 0;
  padding: 0;

  &__text {
    font-size: 28px;
    color: $normal-text;
    text-decoration: none;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    &_type_green {
      color: $color-primary;
    }
  }
}
