.scroll-to__button {
  border: none;
  background: none;
  text-transform: uppercase;
  color: $normal-text;
  transition: 0.3s;
  font-size: 13px;
  font-weight: 600;
  font-family: $font-family;
  cursor: pointer;
  padding: 0;
  &:hover {
    color: $color-primary;
  }
}

.scroll-to__button_place_modal {
  border: none;
  background: none;
  width: 100%;
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: $normal-text;
  transition: 0.3s;
  cursor: pointer;
  padding: 0;
  &:hover {
    color: $color-primary;
  }
}
