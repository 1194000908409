.product {
  &__container {
    margin: 0 auto;
    padding: 0 15px;
    @include sm {
      max-width: 510px;
      padding: 0;
    }
    @include md {
      max-width: 690px;
    }
    @include lg {
      max-width: 930px;
    }
    @include xl {
      max-width: 1110px;
    }
  }
  &__description {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-items: center;
    &-container {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      overflow: hidden;
      width: 100%;
      margin: 0 10px;
      padding: 15px;
      @include md {
        width: calc(100% / 12 * 12 - 240px);
        margin: 0 auto;
      }
      @include lg {
        width: calc(100% / 12 * 6 - 40px);
      }
    }
    &-img {
      max-width: 350px;
      height: 100%;
      width: 100%;
    }
    &-list {
      box-sizing: border-box;
      position: relative;
      padding: 0 0 0 20px;
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 15px;

      @include lg {
        width: calc(100% / 12 * 6 - 40px);
      }
    }
    &-item {
      font-size: 1rem;
      font-weight: 400;
      &-ico {
        color: $color-primary;
        position: absolute;
        left: 0;
      }
    }
    &-additional {
      box-sizing: border-box;
      padding: 10px;
      font-family: $font-family;
      @include sm {
        padding: 10px 0 0;
      }
    }
  }
  &__gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    list-style: none;
    padding: 25px 0 0;
    &-item {
      box-shadow: 0 2px 15px rgb(0 0 0 / 10%);
      border-radius: 5px;
      overflow: hidden;
      width: 100%;
      margin: 0 10px;
      @include sm {
        margin: 0;
      }
      @include md {
        width: calc(100% / 12 * 6 - 10px);
      }
      @include lg {
        width: calc(100% / 12 * 4 - 14px);
      }
    }
    &-img {
      width: 100%;
      height: 100%;
    }
  }
  &__paragraph {
    padding-bottom: 20px;
    &-text {
      font-family: $font-family;
      font-size: 1.25rem;
      font-weight: 500;
    }
  }
}
