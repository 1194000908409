$color-primary: #1bbd36;
$color-secondary: #111111;
$color-white: #ffffff;

$background-dark: #f7f7f7;
$color-icons: #5c9f24;
$box-shadow-black: rgba(0, 0, 0, 0.15);
$box-shadow-green: rgba(92, 159, 36, 0.3);
$partners-border: #ececec;
$form-border: #ced4da;

// text
$copy-text: #545454;
$normal-text: #111111;
$white-text: #ffffff;

// breakpoints
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;

$submenu-width: 220px;
