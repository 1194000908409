.portfolio {
  width: 100%;
  &__list {
    max-width: 510px;
    padding: 0 10px;
    margin: 0 auto;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    @include sm {
      max-width: 510px;
    }
    @include md {
      max-width: 690px;
    }
    @include lg {
      max-width: 930px;
    }
    @include xl {
      max-width: 1110px;
    }
    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(100% / 12 * 12);
      overflow: hidden;
      @include sm {
        width: calc(100% / 12 * 6 - 15px);
      }
      @include md {
        width: calc(100% / 12 * 6 - 15px);
      }
      @include lg {
        width: calc(100% / 12 * 4 - 20px);
      }
    }
    &-img {
      width: 100%;
      height: 100%;
    }
  }
}
