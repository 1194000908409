.accordion {
  display: none;
  flex-direction: column;
  gap: 7px;
  list-style: none;
  padding: 10px 0 0 0;
  margin: 0;
  &_active {
    display: flex;
  }
  &__item {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    position: relative;
  }
  &__link {
    text-decoration: none;
    display: block;
    color: rgba(77, 70, 67, 0.7);

    &:hover {
      color: #1bbd36;
      background-color: rgba($color-primary, 0.1);
    }
    &::before {
      content: "";
      display: block;
      border-bottom: solid rgba($color-primary, 0.3) 1px;
      position: absolute;
      width: 100%;
      bottom: -4px;
    }
  }
}
