.burger {
  padding: 0;
  background: $color-white;
  border: none;
  height: 25px;
  width: 25px;
  position: absolute;
  right: 25px;
  top: 17px;
  color: $normal-text;
  transition: all ease-in 0.4s;
  @include lg {
    display: none;
  }
  &:hover {
    color: $color-primary;
  }
  &__ico {
    font-size: 25px;
  }
}
