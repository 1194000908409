@-webkit-keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

.about {
  &__container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    gap: 30px;
    @include sm {
      max-width: 510px;
    }
    @include md {
      max-width: 690px;
      justify-content: center;
    }
    @include lg {
      max-width: 930px;
    }
    @include xl {
      max-width: 1110px;
    }
  }
  &__video {
    max-height: 315px;
    position: relative;
    @include md {
      width: calc(100% / 12 * 12 - 155px);
    }
    @include lg {
      width: calc(100% / 12 * 6 - 35px);
    }
    &-img {
      padding-top: 5px;
      width: 100%;
      height: 100%;
    }
    &-button {
      width: 94px;
      height: 94px;
      background: radial-gradient(
        $color-primary 50%,
        rgba(158, 243, 109, 0.4) 52%
      );
      border-radius: 50%;
      border: none;
      cursor: pointer;
      display: block;
      position: absolute;
      left: calc(50% - 47px);
      top: calc(50% - 47px);
      overflow: hidden;
      &::before {
        content: "";
        position: absolute;
        width: 120px;
        height: 120px;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
        -webkit-animation: pulsate-btn 2s;
        animation: pulsate-btn 2s;
        -webkit-animation-direction: forwards;
        animation-direction: forwards;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: steps;
        animation-timing-function: steps;
        opacity: 1;
        border-radius: 50%;
        border: 5px solid $color-primary;
        top: -15%;
        left: -15%;
        background: rgba(198, 16, 0, 0);
      }
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-40%) translateY(-50%);
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 15px solid $color-white;
        z-index: 100;
        transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
      &:hover::after {
        border-left: 15px solid $color-primary;
        transform: scale(20);
      }
      &:hover::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-40%) translateY(-50%);
        width: 0;
        height: 0;
        border: none;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 15px solid $color-white;
        z-index: 200;
        -webkit-animation: none;
        animation: none;
        border-radius: 0;
      }
    }
  }
  &__list {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 9px;
    margin: 0;
    padding: 0 20px;
    @include md {
      width: calc(100% / 12 * 12 - 155px);
    }
    @include lg {
      width: calc(100% / 12 * 6 - 35px);
    }

    &-title {
      font-size: 1.5rem;
      margin: 0;
      padding: 0 0 15px;

    }
    &-ico {
      color: $color-primary;
      font-size: 14px;
      padding-right: 5px;
    }
  }
}
