.section {
  padding: 60px 0;
  overflow: hidden;
  &__title {
    text-align: center;
    padding-bottom: 30px;
    &-text {
      font-size: 32px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 20px;
      padding-bottom: 20px;
      position: relative;
      &_place_description {
        margin: 0;
      }
      &::after {
        content: "";
        position: absolute;
        display: block;
        width: 50px;
        height: 3px;
        background: $color-primary;
        bottom: 0;
        left: calc(50% - 25px);
      }
    }
    &-img {
      max-height: 60px;
    }
  }
  &_theme_dark {
    background: $background-dark;
  }
}
